.h2Heading,
.h2Heading1 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 19px;
  font-weight: 600;
}
.h2Heading {
  font-size: 20px;
  line-height: 35px;
}
.h2Heading1 {
  margin: 0;
  font-size: 20px;
  font-family: inherit;
  color: #0d0d25;
  line-height: 32px;
}
.suggestionButtons {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 0px;
  color: #7150e0;
  font-family: Inter;
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
}
.suggestionButtonsContainer {
  max-width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
