.unionIcon {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 1px;
  transform: scale(2.25);
}

.wrapperUnion {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h2Heading {
  /* height: 9px; */
  position: relative;
  line-height: 19px;
  text-transform: uppercase;
  display: inline-block;
  min-width: 35px;
}

.topBarNewMessage1 {
  flex-direction: column;
  padding: 3.5px 0 0;
}

.commercial1,
.commercialDesign,
.topBarNewMessage1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.commercialDesign {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.commercial1 {
  height: 24px;
  flex-direction: column;
  padding: 8px 0 0;
  box-sizing: border-box;
}

.button {
  height: 32px;
  flex: 1;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  width: auto;
  max-width: 350px;
}

.openInFull {
  display: none;
}

.closeIcon,
.openInFull {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.closeWrapper,
.topBarNewMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px 0 0;
}

.closeWrapper {
  width: 15px;
}

.topBarNewMessage {
  align-self: stretch;
  border-bottom: 1px solid #eff1f6;
  flex-direction: row;
  padding: 9px 9px 7px;
  gap: 26.5px;
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: left;
  font-size: 12px;
  color: #ab80f1;
  font-family: Inter;
  background-color: rgba(255, 255, 255, 1);
}

.h2Heading1,
.h2Heading2 {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  color: #0d0d25;
  text-align: left;
  min-width: 120px;
}

.stepIndicator {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  color: #758199;
  font-weight: normal;
  text-align: left;
  min-width: 120px;
}

.h2Heading2 {
  height: 10px;
  font-size: 14px;
  color: #758199;
  min-width: 39px;
}

.h2HeadingAssigned {
  font-size: 14px;
  color: #758199;
  min-width: 39px;
  display: inline-block;
  font-family: Inter;
}

.logan,
.plusIcon {
  position: relative;
  overflow: hidden;
}

.textInput {
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  line-height: 19px;
  color: #0d0d25;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.plusIcon {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}

.textInputContainer,
.h2HeadingGroup {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.textInputContainer {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 5px 12px;
  gap: 8px;
  width: 90%;
}

.dropDownInputContainer {
  /* width: 90%; */
  align-self: stretch;
  /* height: 32px; */
  font-weight: 500;
  color: #0d0d25;
  font-family: Inter;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.h2HeadingGroup {
  flex-direction: column;
  gap: 12px;
}

.h2Heading3 {
  height: 10px;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-family: Inter;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 59px;
}

.mentz,
.plusIcon1 {
  position: relative;
  overflow: hidden;
}

.mentz {
  width: 43px;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 10px;
  line-height: 19px;
  color: #0d0d25;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
}

.plusIcon1 {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}

.button2,
.h2HeadingContainer {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button2 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 9px 12px;
  gap: 8px;
}

.h2HeadingContainer {
  flex-direction: column;
  gap: 12px;
}

.h2Heading4 {
  height: 10px;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-family: Inter;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 32px;
}

.loganmentzcbrecom,
.plusIcon2 {
  position: relative;
  overflow: hidden;
}

.loganmentzcbrecom {
  width: 160px;
  font-size: 14px;
  line-height: 10px;
  font-weight: 500;
  font-family: Inter;
  color: #0d0d25;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plusIcon2 {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}

.button3,
.frameDiv {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button3 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 9px 12px;
  gap: 8px;
}

.frameDiv {
  flex-direction: column;
  gap: 12px;
}

.h2Heading5 {
  height: 10px;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-family: Inter;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 42px;
}

.input,
.plusIcon3 {
  position: relative;
  overflow: hidden;
}

.input {
  width: 112px;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 10px;
  line-height: 19px;
  color: #0d0d25;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
}

.plusIcon3 {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}

.button4,
.h2HeadingParent1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button4 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 9px 12px;
  gap: 8px;
}

.h2HeadingParent1 {
  flex-direction: column;
  gap: 12px;
}

.button5,
.h2Heading6 {
  font-family: Inter;
  font-size: 14px;
}

.h2Heading6 {
  height: 10px;
  position: relative;
  line-height: 19px;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 80px;
}

.button5 {
  align-self: stretch;
  height: 32px;
  font-weight: 500;
  color: #0d0d25;
}

.h2HeadingParent2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.h2Heading7 {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-family: Inter;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 115px;
}

.cbreGlobal,
.plusIcon4 {
  position: relative;
  overflow: hidden;
}

.cbreGlobal {
  flex: 1;
  font-size: 14px;
  line-height: 10px;
  font-weight: 500;
  font-family: Inter;
  color: #0d0d25;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plusIcon4 {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}

.button6,
.h2HeadingParent3 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button6 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 9px 12px;
  gap: 8px;
  white-space: nowrap;
}

.h2HeadingParent3 {
  flex-direction: column;
  gap: 12px;
}

.h2Heading8 {
  height: 10px;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-family: Inter;
  color: #758199;
  text-align: left;
  display: inline-block;
}

.m15m,
.plusIcon5 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.m15m {
  width: 105px;
  font-size: 14px;
  line-height: 10px;
  font-weight: 500;
  font-family: Inter;
  color: #0d0d25;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plusIcon5 {
  height: 18px;
  width: 18px;
  display: none;
}

.button7,
.h2HeadingParent4 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button7 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 9px 12px;
  gap: 8px;
}

.h2HeadingParent4 {
  flex-direction: column;
  gap: 12px;
}

.h2Heading9 {
  height: 10px;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  font-family: Inter;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 119px;
}

.plusIcon6,
.romeoMichiganUnited {
  position: relative;
  overflow: hidden;
}

.romeoMichiganUnited {
  width: 179px;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 10px;
  line-height: 19px;
  color: #0d0d25;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
}

.plusIcon6 {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}

.button8,
.h2HeadingParent5 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.button8 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  flex-direction: row;
  padding: 9px 12px;
  gap: 8px;
}

.h2HeadingParent5 {
  flex-direction: column;
  gap: 12px;
}

.h2Heading10,
.romeoMichiganUnited1 {
  font-family: Inter;
  font-size: 14px;
  height: 10px;
  position: relative;
  line-height: 19px;
  text-align: left;
  display: inline-block;
}

.h2Heading10 {
  color: #758199;
  min-width: 93px;
}

.romeoMichiganUnited1 {
  width: 179px;
  border: 0;
  outline: 0;
  font-weight: 500;
  background-color: transparent;
  color: #0d0d25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
}

.plusIcon7 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.button9 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 9px 12px;
  gap: 8px;
}

.h2HeadingParent,
.h2HeadingParent6 {
  align-self: stretch;
  flex-direction: column;
}

.h2HeadingParent6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.h2HeadingParent {
  gap: 15px;
}

.frameGroup,
.frameWrapper,
.h2HeadingParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameWrapper {
  flex: 1;
  flex-direction: column;
  padding: 2px 0 0;
}

.frameGroup {
  align-self: stretch;
  flex-direction: row;
  gap: 20px;
}

.button10 {
  align-self: stretch;
  height: 32px;
  width: 265px;
}

.expandableInstanceInner,
.frameParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameParent {
  margin: 0;
  flex: 1;
  flex-direction: column;
  gap: 35px;
}

.expandableInstanceInner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 14px 0 20px;
  /* top right bottom left */
}

.expandableInstance,
.expandableInstanceWrapper,
.topBarNewMessageParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.expandableInstance {
  flex: 1;
  flex-direction: column;
  gap: 38px;
}

.expandableInstanceWrapper,
.topBarNewMessageParent {
  box-sizing: border-box;
}

.expandableInstanceWrapper {
  align-self: stretch;
  flex-direction: row;
  flex-grow: 1;
  padding-bottom: 1rem;
  overflow: auto;
}

.topBarNewMessageParent {
  flex: 1;
  background-color: #fff;
  border-top: 1px solid #eff1f6;
  border-right: 1px solid #eff1f6;
  /* border-bottom: 1px solid #eff1f6; */
  flex-direction: column;
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollBarContainer {
  align-self: stretch;
  width: 8px;
  position: relative;
  border-radius: 8px;
  background-color: #6e717c;
}

.commercial,
.scrollBar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.scrollBar {
  height: 1003px;
  width: 9px;
  border-radius: 0 8px 8px 0;
  background-color: #fff;
  border-top: 1px solid #eff1f6;
  border-right: 1px solid #eff1f6;
  border-bottom: 1px solid #eff1f6;
  box-sizing: border-box;
  padding: 8px 0 933px;
}

.commercial {
  width: 100%;
  /* max-width: 100%; */
  line-height: normal;
  letter-spacing: normal;
  height: 100%;
}

.frameGroupScore {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
}

.h2HeadingWrapperScore {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.h2HeadingScore {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 39px;
}

.infoIconScore {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.frameParentScore {
  flex-direction: column;
  gap: 63.2px;
}

.frameParentScore {
  gap: 55.2px;
  text-align: left;
  font-size: 14px;
  color: #758199;
  font-family: Inter;
}

.scoreFrame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
}

.selectTableDiv {
  width: 305px;
  position: absolute;
}

.formHeaderDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.integrationIdentifier {
  background-color: transparent;
  color: #0d0d25;
  opacity: 0.5;
  margin-left: 4px;
}

.fieldContainer {
  width: 100%;
}

.loadingText {
  font-size: 14px;
  color: #758199;
  min-width: 39px;
  margin-left: 20px;
  font-family: Inter;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.secondaryText {
  font-size: 14px;
  color: #758199;
  font-family: Inter;
}

.bottomSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  padding: 20px 0 20px 0;
  background-color: #ffffff;
  border-top: 1px solid #eff1f6;
  border-right: 1px solid #eff1f6;
}

.connectToHubspotText {
  font-size: 14px;
  color: #758199;
  font-family: Inter;
  margin-bottom: 14px;
  text-align: center;
}

.mapColumnHeaderDropDown {
  width: 140px;
}

.mapColumnFieldName {
  position: relative;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  text-align: left;
  font-size: 14px;
  color: #758199;
  min-width: 39px;
  width: 100px;
}

.footerMessage {
  position: relative;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  text-align: left;
  font-size: 14px;
  color: #758199;
}

.mapColumnRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.mapColumnTitleHeader {
  display: flex;
  width: 100%;
  padding: 10px 0 10px 0;
  margin: 15px 0 10px 0;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #eff1f6;
  border-bottom: 1px solid #eff1f6;
}

.mapColumnIntegrationPropertyHeader {
  position: relative;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  text-align: left;
  font-size: 14px;
  color: #758199;
  min-width: 39px;
  width: 140px;
}

.descriptionText {
  position: relative;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  text-align: left;
  font-size: 14px;
  color: #758199;
  min-width: 39px;
  width: 180px;
  text-align: center;
}

.customLink {
  position: relative;
  line-height: 19px;
  display: inline-block;
  font-family: Inter;
  text-align: left;
  font-size: 14px;
  color: #235ac7;
  min-width: 39px;
  width: 180px;
  text-align: center;
}

.successContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
  padding-top: 40px;
}
