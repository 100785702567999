.parent {
    align-self: stretch;
    height: 30px;
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    color: #0d0d25;
    width: auto;
  }
  .topBarNewMessageInner {
    width: 204px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 7px 0 0;
    box-sizing: border-box;
  }
  .button {
    height: 32px;
    flex: 1;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    color: #0d0d25;
    width: auto;
  }
  .closeIcon,
  .openInFull {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 18px;
  }
  .openInFullParent,
  .topBarNewMessage,
  .topBarNewMessageChild {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .openInFullParent {
    flex-direction: row;
    gap: 16px;
  }
  .topBarNewMessage,
  .topBarNewMessageChild {
    flex-direction: column;
    padding: 7px 0 0;
  }
  .topBarNewMessage {
    align-self: stretch;
    border-bottom: 1px solid #eff1f6;
    flex-direction: row;
    padding: 9px 10px 7px;
    gap: 32px;
    top: 0;
    z-index: 99;
    position: sticky;
    flex-shrink: 0;
  }
  .plusIcon {
    width: 18px;
    height: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .h2Heading {
    position: relative;
    line-height: 19px;
    font-weight: 500;
    display: inline-block;
    /* min-width: 115px; */
    /* color: #7150e0; */
    font-size: 14px;

    /*Style to make it look like button*/
    border-color: rgba(201, 178, 240, 0.2);
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    border-width: 1px;
    border-style: solid;
    padding: 6px 16px;
    font-weight: 450;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h2Heading:hover {
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px; */
    background-color: #F0F1F6;
  }

  .recentChatDirectoryList {
    font-family: Inter;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 60px;
  }

  .h2HeadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .div {
    position: relative;
    font-size: 16.8px;
    line-height: 23px;
    font-weight: 500;
    display: inline-block;
    min-width: 12px;
    color: #7150e0;
  }
  .frameGroup {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;
    position: fixed;
    background-color: #fff;
    z-index: 99;
    top: 0;
    left: 0;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    width: 210px;
  }
  .sectionTitle {
    position: relative;
    font-size: 10px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(15, 20, 39, 0.6);
    display: inline-block;
    min-width: 63px;
    padding-top: 10px;
    padding-bottom: 6px;
  }
  .chatTitle {
    position: relative;
    line-height: 19px;
    font-weight: 500;
    cursor: pointer;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .chatTitle:hover {
    background-color: #F0F1F6;
    border-radius: 5px;
    transition: 0.3s all ease;
  }
  .h2Heading3 {
    display: inline-block;
    min-width: 78px;
  }
  .h2Heading4 {
    height: 10px;
    display: flex;
    align-items: center;
    min-width: 60px;
  }
  .frameParent,
  .h2HeadingParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .h2HeadingParent {
    gap: 10px;
    color: #0f1427;
  }
  .frameParent {
    gap: 21px;
    padding: 7px 15px 30px;
    height: 100%;
  }
  .h2Heading5 {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(15, 20, 39, 0.6);
    display: inline-block;
    min-width: 38px;
  }
  .h2Heading5,
  .h2Heading6,
  .h2Heading7,
  .h2Heading8 {
    position: relative;
    line-height: 19px;
    font-weight: 500;
  }
  .h2Heading6 {
    display: inline-block;
    min-width: 90px;
  }
  .h2Heading7,
  .h2Heading8 {
    min-width: 118px;
  }
  .h2Heading7 {
    display: inline-block;
  }
  .h2Heading8 {
    height: 10px;
    display: flex;
    align-items: center;
  }
  .assistants,
  .h2HeadingGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .h2HeadingGroup {
    gap: 10px;
    color: rgba(15, 20, 39, 0.9);
  }
  .assistants {
    /* width: 230px; */
    overflow-y: auto;
    max-width: 100%;
    /* max-height: 350px; */
    overflow-y: scroll;
    /* margin-left: 15px; */
    /* box-shadow: 2px 3px 15px 1px rgba(106, 154, 227, 0.06);
    backdrop-filter: blur(24px); */
    border-radius: 16px;
    /* background-color: #fff; */
    /* border: 1px solid #eff1f6; */
    box-sizing: border-box;
    overflow: hidden;
    gap: 32px;
    height: 100%;
    font-family: Inter;
  }
  .lightningIcon {
    width: 18px;
    height: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .lightningWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 7px 0 0;
  }
  .h2Heading9 {
    align-self: stretch;
    height: 10px;
    position: relative;
    line-height: 19px;
    font-weight: 500;
    display: inline-block;
  }
  .h2HeadingContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px 0 0;
    box-sizing: border-box;
    min-width: 270px;
    max-width: 100%;
  }
  .buttonIcon {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
  }
  .frameDiv {
    align-self: stretch;
    box-shadow: 0 1px 10px rgba(226, 229, 236, 0.4);
    border-radius: 14px;
    background-color: #fff;
    border: 1px solid #a179e4;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 7px 6px 15px;
    gap: 16px;
    max-width: 100%;
  }
  .frameContainer {
    flex: 1;
    flex-direction: column;
    gap: 32px;
    max-width: 100%;
  }
  .frameContainer,
  .textField,
  .textFieldInner,
  .topBarNewMessageParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .textFieldInner {
    align-self: stretch;
    flex-direction: row;
    padding: 0 10px;
    box-sizing: border-box;
    max-width: 100%;
    flex-shrink: 0;
    color: #6e717c;
  }
  .textField,
  .topBarNewMessageParent {
    flex-direction: column;
  }
  .textField {
    align-self: stretch;
    height: 796px;
    border-bottom: 1px solid #eff1f6;
    box-sizing: border-box;
    padding: 443px 10px 28px;
    gap: 120px;
    max-width: 100%;
  }
  .topBarNewMessageParent {
    flex: 1;
    max-width: calc(100% - 9px);
    text-align: left;
    font-size: 14px;
    color: #7150e0;
    font-family: Inter;
  }
  .scrollBarChild {
    align-self: stretch;
    width: 8px;
    position: relative;
    border-radius: 8px;
    background-color: #6e717c;
  }
  .messageText,
  .scrollBar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .scrollBar {
    height: 845px;
    width: 9px;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid #eff1f6;
    padding: 0 0 783px;
  }
  .messageText {
    width: 571px;
    box-shadow: 2px 3px 12px 1px rgba(72, 80, 109, 0.1),
      11px 1px 30px 20px rgba(72, 80, 109, 0.17);
    background-color: #fff;
    border: 1px solid #eff1f6;
    max-width: 100%;
    line-height: normal;
    letter-spacing: normal;
  }
  .infoHelperText {
    color: rgba(15, 20, 39, 0.6);
    font-size: 13px;
    line-height: 19px;
    font-family: Inter;
  }

  .noChats {
    color: rgba(15, 20, 39, 0.6);
    font-size: 13px;
    line-height: 19px;
    font-family: Inter;
  }

  @media screen and (max-width: 525px) {
    .frameContainer {
      gap: 16px;
    }
    .textField {
      padding-top: 187px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .topBarNewMessage {
      gap: 16px;
    }
    .textField {
      height: auto;
      gap: 60px;
    }
  }
  