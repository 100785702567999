.addyAssistant {
    text-decoration: none;
    position: relative;
    line-height: 10px;
    font-weight: 500;
    color: inherit;
    display: inline-block;
    min-width: 102px;
    white-space: nowrap;
  }
  .plusIcon {
    display: none;
    min-height: 18px;
  }
  .expandIcon,
  .plusIcon {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .addyAssistantContainer,
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    overflow: hidden;
    justify-content: space-between;
    padding: 5px 12px;
    gap: 20px;
  }
  .addyAssistantContainer {
    width: 216px;
    justify-content: flex-start;
  }
  .h2Heading {
    text-decoration: none;
    position: relative;
    line-height: 10px;
    color: inherit;
    display: inline-block;
    min-width: 55px;
  }
  .headingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .closeIcon,
  .openInFull {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 18px;
  }
  .actionButtons,
  .actionsContainer,
  .topBarNewMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .actionButtons {
    width: 36px;
    padding: 0;
    box-sizing: border-box;
    gap: 16px;
  }
  .actionsContainer,
  .topBarNewMessage {
    padding: 0 13px 0 0;
  }
  .topBarNewMessage {
    align-self: stretch;
    border-bottom: 1px solid #eff1f6;
    justify-content: space-between;
    padding: 9px 16px 7px;
    top: 0;
    z-index: 9999;
    position: sticky;
    gap: 12.5px;
    text-align: left;
    font-size: 14px;
    color: #0d0d25;
    font-family: Inter;
  }
  .h2Heading1 {
    height: 17px;
    position: relative;
    line-height: 160%;
    display: inline-block;
  }
  .h2Heading2 {
    position: relative;
    line-height: 19px;
  }
  .chatWithYour {
    margin-block-start: 0;
    margin-block-end: 9px;
  }
  .changeTheInstructions {
    margin: 0;
  }
  .chatWithYourContainer {
    align-self: stretch;
    height: 82px;
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #485165;
    display: inline-block;
  }
  .h2HeadingGroup,
  .h2HeadingParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .h2HeadingGroup {
    flex: 1;
    flex-direction: column;
    gap: 9px;
    min-width: 188px;
    font-size: 16px;
  }
  .h2HeadingParent {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
  .h2Heading3 {
    height: 17px;
    position: relative;
    line-height: 160%;
    display: inline-block;
  }
  .h2Heading4 {
    position: relative;
    line-height: 19px;
  }
  .addNecessaryBits {
    margin-block-start: 0;
    margin-block-end: 9px;
  }
  .youCanChange {
    margin: 0;
  }
  .addNecessaryBitsContainer {
    align-self: stretch;
    height: 61px;
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #485165;
    display: inline-block;
  }
  .frameDiv,
  .h2HeadingContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameDiv {
    flex: 1;
    flex-direction: column;
    gap: 9px;
    min-width: 188px;
    font-size: 16px;
  }
  .h2HeadingContainer {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
  .h2Heading5 {
    height: 17px;
    position: relative;
    line-height: 160%;
    display: inline-block;
  }
  .h2Heading6 {
    position: relative;
    line-height: 19px;
  }
  .addNecessaryBits1 {
    margin-block-start: 0;
    margin-block-end: 9px;
  }
  .youCanChange1 {
    margin: 0;
  }
  .addNecessaryBitsContainer1 {
    align-self: stretch;
    height: 61px;
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #485165;
    display: inline-block;
  }
  .h2HeadingParent2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 9px;
    min-width: 188px;
    font-size: 16px;
  }
  .frameParent,
  .h2HeadingParent1 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .h2HeadingParent1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
  .frameParent {
    /* display: none; */
    flex-direction: column;
    /* padding: 0 7px 0 0; */
    gap: 40px;
    width: 100%;
    /* border: 1px solid red; */
    flex-grow: 1;
    /* padding-top: 15px; */
  }
  .chat,
  .textField,
  .top {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .textField {
    display: flex; /* Make this a flexbox container */
    /* flex-grow: 1; */
    
    align-self: stretch;
    /* flex-direction: column; */
    justify-content: space-between;
    max-width: 100%;
    height: fit-content;
    text-align: left;
    font-size: 24px;
    color: #0d0d25;
    font-family: Inter;
    /* padding-top: 52px; */
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 10px;

    /* max-height: 200px; */
    height: 100%;
    /* max-height: 90vh; */
    overflow-y: scroll;
    /* scrollbar-width: none; */
    /* for Firefox */
    /* -ms-overflow-style: none; */
    scrollbar-width: thin;
  }
  .paddingTop50 {
    padding-top: 50px;
  }
  .paddingTop0 {
    padding-top: 0px;
  }
  .chat,
  .top {
    justify-content: flex-start;
  }
  .top {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
  }
  .chat {
    width: 100%;
    /* position: relative; */
    background-color: #fff;
    box-sizing: border-box;
    flex-direction: row;
    line-height: normal;
    letter-spacing: normal;
    height: 100%;
  }
  .welcomeAndSuggestionsContainer {
    max-width: 100%;
    /* min-height: 500px; */
    /* max-height: 500px; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    /* for Firefox */
    -ms-overflow-style: none;
    height: 100%;
  }

.welcomeAndSuggestionsContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari and Opera */
}

.breadCrumbsNavParent {
    position: absolute;
    border-radius: 20px;
    background-color: #EFF1F6;
    /* width: 85%; */
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    left: 45%;
    min-width: 100px;
    top: 70px;
    transform: translate(-50%, -50%);
    height: fit-content;
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding-right: 20px; */
    /* height: 20px; */
    /*Make opacity transition ease*/
    transition: opacity 0.5s ease;
}
