.suggestion-button {
  border-radius: 12px;
  background-color: #fff;
  /* box-shadow: 0 1px 10px rgba(226, 229, 236, 0.4); */
  /* border: 1px solid #eff1f6; */
  border: 1px solid #ededee;
  /* border: 1px solid #a179e4; */
  display: flex;
  flex-direction: row;
  padding: 7px 11px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
  line-height: 19px;
  font-weight: 500;
  height: 25px;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  color: #485165;
  opacity: 90%;
  font-weight: normal;
  font-size: 13px;
}

.suggestion-button:hover {
  background-color: #f0f1f6;
  color: rgba(0, 0, 0, 0.9);
  transition: background-color 0.3s ease;
}

/**
    text-overflow: ellipsis;
    width: fit-content;
    max-width: fit-content;
    overflow: hidden;
    white-space: nowrap;
}
/* .suggestion-button:hover {
    background-color: #f6f7fb;
    max-width: fit-content; 
    white-space: nowrap; 
    overflow: visible;
    transition: background-color 0.3s ease;
}

**/

.selected-text-action {
  width: fit-content;
  font-size: 12px;
  line-height: 14px;
  padding: 1px 10px;
}

.selected-text-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  margin-bottom: 30px;
}

.button-instance-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.actions-container {
  width: 100%;
}

.action-title {
  font-size: 13px;
  line-height: 14px;
  color: #485165;
  font-family: Inter;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: right;
}

.selected-text-action:hover {
  background-color: #f6f7fb;
  transition: background-color 0.3s ease;
}

.selected-text-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  gap: 8px;
  border: 1px solid #eff1f6;
  border-radius: 12px;
  width: 92%;
}
.hot-tip-parent-container {
  width: 100%;
}

.hot-tip-parent {
  margin-bottom: 10px;
}

.selected-text-close-button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.selected-text-close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.selected-text-header-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.selected-text-header {
  font-size: 13px;
  color: #7150e0;
  font-family: Inter;
}

.selected-text {
  font-size: 12px;
  line-height: 130%;
  color: #485165;
  text-align: left;
}

.focus-text-mark {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  background: transparent;
}
.feedback-request-text {
  text-align: center;
  width: 100%;
}
.gradient-title {
  font-size: 48px; /* Adjust as needed */
  font-weight: bold; /* Optional for styling */
  background: linear-gradient(90deg, #4a90e2, #aa68e2, #ff6f61);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.feedback-request-text a {
  color: #7150e0;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.lightning-instance {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 55px;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.lightning-instance::-webkit-scrollbar {
  width: 8px;
}

.lightning-instance::-webkit-scrollbar-track {
  background: transparent;
}

.lightning-instance::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

.lightning-instance:hover {
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.lightning-instance:hover::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
}

.selected-text-action-div {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  align-items: center;
  gap: 6px;
  width: 100%;
  /* flex-wrap: wrap; */
  overflow-x: scroll;

  /* Code to hide scrollbar */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
}

.selected-text-action-div::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari and Opera */
}

.lightning-icon {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.button-icon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  cursor: pointer;
}

.lightning-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.addy-prompt-input {
  background-color: transparent;
  border-color: transparent;
  resize: none;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: scroll;
  overflow-wrap: break-word;
  /* word-wrap: break-word; */
  height: 1em;
  flex: 1;
  position: relative;
  line-height: 1em;
  font-weight: 500;
  display: inline-block;
  min-width: 93px;
  max-width: 100%;
  font-family: Inter;
  font-size: 16px;
  outline: none;
  min-height: 1em !important;
  max-height: 5em;
  width: 100%;
}

.addy-prompt-input::-webkit-scrollbar {
  width: 0.5em;
}

.addy-prompt-input::-webkit-scrollbar-track {
  background-color: transparent;
}

.addy-prompt-input::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.addy-prompt-input::placeholder {
  /* color: #939599; */
  color: rgba(0, 0, 0, 0.5);
}

.button-instance,
.button-instance-inner {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
}

.button-instance-inner {
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(226, 229, 236, 0.4);
  /* border: 1px solid #a179e4; */
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  gap: 8px;
  text-align: left;
  color: #6e717c;
  width: 100%;
  /* margin: 0 auto; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  background-color: #e8e9ed;
  /* border: 1px solid #a179e4; */
  /* border: 1px solid #e3e3e4; */
}

.button-instance {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  /* gap: 15px !important; */
  text-align: center;
  font-size: 14px;
  color: #7150e0;
  font-family: Inter;
  position: absolute;
  width: -webkit-fill-available;
  padding-top: 20px;
  padding-right: 10px;
}

.button-instance-padding {
  padding-right: 20px;
  padding-left: 20px;
}

.lightning-instance-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 10px;
  padding-bottom: 15px;
  width: 100%;
  /* height: 55px; */
  overflow-x: scroll;

  /* Code to hide scrollbar */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
}

.more-suggestions-title {
  font-size: 13px;
  color: #485165;
  text-align: right;
}

.description-helper-text {
  color: #6e717c !important;
  font-weight: 400;
  font-size: 14px !important;
}

.input-actions-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-action-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
}

.input-action-button:hover {
  background-color: #dcdde0 !important;
  /* background-color: #D6C8EF !important; */
  /* transition: background-color 0.3s ease !important;
    border: 1px solid #D5C9EE !important;
    color: #8740FF !important; */
}
