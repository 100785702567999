.comparison-modal-title{
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #0d0d25;
    line-height: 27px;
}

.Comparison-list {
    width: 100%;
    gap: 20px;
}

.Comparison-view-all {
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    color: #6e717c;
    transition: text-decoration 0.2s ease-in-out;
}
.comparison-section {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.Comparison-view-all:hover {
    text-decoration: underline;
}

.h2-copy {
    color: #7150E0;
    font-size: 14px;
    font-weight: 500;
}