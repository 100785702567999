.h2-heading46 {
  position: relative;
  line-height: 10px;
  white-space: nowrap;
}

.h2-heading-wrapper4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.addy-assistant5 {
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
  min-width: 102px;
  white-space: nowrap;
}

.comparison-footer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

.comparison-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.plus-icon6 {
  display: none;
  min-height: 18px;
}

.expand-icon5,
.plus-icon6 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.button6 {
  align-self: stretch;
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px 12px;
  gap: 20px;
}

.close-icon5,
.open-in-full5 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 18px;
}

.open-in-full-parent1 {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.expand1,
.top-bar-new-message6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.expand1 {
  width: 300px;
  justify-content: flex-start;
  gap: 32px;
}

.top-bar-new-message6 {
  height: 50px;
  border-bottom: 1px solid #eff1f6;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 9px 16px;
  top: 0;
  z-index: 99;
  position: sticky;
  /* gap: 20px; */
  text-align: left;
  font-size: 14px;
  color: #0d0d25;
  font-family: Inter;
}

.chevron-right-icon,
.home {
  position: absolute;
  display: none;
}

.home {
  top: calc(50% - 10px);
  left: 64px;
  letter-spacing: -0.02em;
  line-height: 22px;
  font-weight: 600;
}

.chevron-right-icon {
  top: 16px;
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.image-placeholder {
  height: 36px;
  width: 36px;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url(/public/image-placeholder@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.h2-heading47 {
  height: 10px;
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 36px;
}

.create-a-sales,
.hey {
  margin: 0;
}

.h2-heading48 {
  align-self: stretch;
  height: 29px;
  position: relative;
  line-height: 19px;
  font-weight: 500;
  color: #6e717c;
  display: flex;
  align-items: center;
}

.h-heading,
.union {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.union {
  flex: 1;
  flex-direction: column;
  gap: 12px;
  /* min-width: 305px; */
  font-size: 14px;
  color: #303032;
}

.h-heading {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.chevron-right-icon1,
.home1 {
  position: relative;
  display: none;
}

.home1 {
  width: 82px;
  letter-spacing: -0.02em;
  line-height: 22px;
  font-weight: 600;
}

.chevron-right-icon1 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.union-icon {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 1px;
  transform: scale(2.333);
}

.wrapper-union {
  height: 15px;
  width: 15px;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-placeholder1 {
  height: 36px;
  width: 36px;
  border-radius: 54px;
  /* border: 1px solid #eff1f6; */
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 9px 9px 11px 9px;
  border: 1px solid #eff1f6;
}

.h2-heading49 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 105px;
}

.blank-line,
.dear-john-doe,
.im-a-senior-level {
  margin: 0;
}

.im-a-senior-level {
  white-space: pre-wrap;
}

.h2-heading50 {
  line-height: 19px;
  font-weight: 500;
  color: #6e717c;
  display: flex;
  align-items: center;
}

.h2-heading-parent24 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.copy-response {
  cursor: pointer;
}

.thumb-icon2 {
  height: 15px;
  width: 15px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 5px;
}

.h2-heading51 {
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
}

.thumb-parent {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.thumb-icon {
  height: 15px;
  width: 15px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 10px;
}

.h2-heading52 {
  position: relative;
  line-height: 10px;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  /* min-width: 92px; */
  width: fit-content;
}

.thumb-group {
  flex-direction: row;
  justify-content: flex-start;
}

.thumb-group {
  height: 18px;
  display: flex;
  align-items: center;
}

.show-message-actions {
  opacity: 1 !important;
}

.first-message {
  padding-top: 40px;
}

.last-message {
  padding-bottom: 30px;
}
.small-chat-container {
  max-height: 90%;
  overflow-y: scroll;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.small-chat-container .frame-parent1 {
  min-width: unset !important;
}

.small-chat-container .selected-text-parent {
  width: unset !important;
  max-width: 100%;
}

.small-chat-container .h2-heading50 {
  font-size: 13px;
}

.small-chat-container::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari and Opera */
}
.height-100-percent {
  height: 100%;
}

.frame-parent2 {
  width: 100%;
  height: 35px;
  display: flex;
  /*It's displayed when parent is hovered over*/
  opacity: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  /* padding: 0 168px 0 0; */
  box-sizing: border-box;
  /* gap: 12px 32px; */
  color: #7150e0;
  /* align-items: flex-start; */
  max-width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.frame-parent1,
.h-heading-group,
.scroll-bar5 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.frame-parent1 {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  /* gap: 24px; */
  min-width: 305px;
  font-size: 14px;
  color: #303032;
}

.h-heading-group,
.scroll-bar5 {
  align-self: stretch;
  justify-content: flex-start;
}

.scroll-bar5 {
  flex-direction: row;
  /* flex-wrap: wrap; */
  gap: 16px;
}

.h-heading-group {
  flex-direction: column;
  gap: 24px;
}

.lightning-icon {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.lightning-wrapper {
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px 0 0;
  box-sizing: border-box;
}

.h2-heading53 {
  align-self: stretch;
  height: 10px;
  position: relative;
  line-height: 19px;
  font-weight: 500;
  display: inline-block;
}

.h2-heading-wrapper5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 0 0;
  box-sizing: border-box;
  min-width: 270px;
  max-width: 100%;
}

.button-icon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}

.frame-parent3 {
  align-self: stretch;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(226, 229, 236, 0.4);
  border: 1px solid #a179e4;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 7px 8px 15px;
  gap: 16px;
  max-width: 100%;
  font-size: 14px;
  color: #6e717c;
}

.text-field5,
.top-bar-new-message5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-field5 {
  box-sizing: border-box;
  justify-content: flex-end;
  gap: 32px;
  width: 100%;
}

.user-message {
  padding-bottom: 35px;
}

.top-bar-new-message5 {
  flex: 1;
  justify-content: flex-start;
  max-width: calc(100% - 9px);
  text-align: left;
  font-size: 16px;
  color: #545f71;
  font-family: Inter;
  /* padding-top: 25px; */
}

.top-bar-new-message5:hover .frame-parent2 {
  opacity: 1 !important;
}

.scroll-bar-child1 {
  align-self: stretch;
  width: 7px;
  position: relative;
  border-radius: 8px;
  background-color: #6e717c;
}

.message-text,
.scroll-bar6 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.scroll-bar6 {
  height: 845px;
  width: 9px;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid #eff1f6;
  padding: 0 1px 783px 0;
}

.message-text {
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 3px 12px 1px rgba(72, 80, 109, 0.1),
    11px 1px 30px 20px rgba(72, 80, 109, 0.17);
  border: 1px solid #eff1f6;
  max-width: 100%;
  letter-spacing: normal;
}

.addy-rate-response-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.addy-copy-response-div {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.model-response-text {
  margin: 0;
  white-space: pre-wrap;
}

/* All elements inside model-response-text */
.model-response-text * {
  margin: 0;
}

.hide {
  display: none;
}

.assistant-message {
  padding-bottom: 5px;
}

.skeleton-line {
  width: 100%;
  height: 15px;
  background-color: #eee;
  margin-bottom: 10px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.short-skeleton-line {
  width: 50%;
}

.skeleton-animation {
  position: absolute;
  z-index: 9;
  top: 0;
  left: -100%;
  /* Start the animation from left to right */
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* Transparent overlay for animation effect */
  animation: loadingSkeleton 1.5s infinite linear;
  /* Animation properties */
}

.staging-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 20px; */
  width: 98%;
  /* border: 1px solid red; */
}

.staging-content {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  border: 1px solid #eff1f6;
  padding: 10px;
  border-radius: 10px;
}

.source-container {
  border: 1px solid #eff1f6;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: rgba(208, 204, 217, 0.2);
  /* background: #F0F1F6; */
}

.source-container:hover {
  background-color: #ece9fe;
}

.source-container-tooltip {
  /* border: 1px solid #EFF1F6; */
  padding: 10px;
  border-radius: 8px;
  /* cursor: pointer; */
}

.source-container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.sources-list {
    width: 75vw;
    overflow-x: auto; 
    white-space: nowrap; 
    display: flex;
    flex-direction: row;
    align-items: center;
    /* flex-wrap: wrap; */
    gap: 30px;
}

.sources-comparison-list {
  align-items: normal;
  width: 75vw;
  overflow-x: auto; 
  /* white-space: nowrap;  */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}

.show-more-sources-text {
  /* text-decoration: underline; */
  font-size: 12px;
  color: #758199;
  cursor: pointer;
  margin-top: 5px;
}

.source-delimiter-symbol {
  font-size: 9px;
  color: #758199;
  background-color: transparent;
}

.source-website-favicon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.source-container-link {
  /* color: rgba(0, 0, 0, 0.4); */
  color: #758199;
  font-size: 12px;
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
}

.source-content-text {
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  color: #758199;
  text-overflow: ellipsis;
}

.custom-tooltip .MuiTooltip-tooltip {
  background-color: black;
  margin-top: 4px;
  color: white;
}

.source-full-text-tooltip {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.source-content-text-tooltip {
    font-size: 14px;
    color: #4b5165;
    margin-bottom: 12px;
    line-height: 21px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sources-title {
  font-size: 13px;
  font-weight: 500;
  color: #303032;
  margin-bottom: 8px;
}

.sources-parent-div {
  margin-bottom: 10px;
}

.source-reference-number {
  /* color: rgba(0, 0, 0, 0.4); */
  color: #758199;
  font-size: 12px;
}

.addy-custom-scroll {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.addy-custom-scroll::-webkit-scrollbar {
  width: 8px;
}

.addy-custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.addy-custom-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

/* Define hover behavior */
.addy-custom-scroll:hover {
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.addy-custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
}

.comparison-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.comparison-list-front {
  display: flex;
  align-items: center;
  gap: 6px;
}

.comparison-list-end {
  display: flex;
  align-items: center;
  gap: 6px;
}

.comparison-number {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: #758199;
  cursor: pointer;
  border: 1px solid #ede7f8;
  margin-right: 5px;
}

.comparison-title {
  font-weight: 600;
  font-size: 14px;
}

.comparison-card {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.comparison-card h3 {
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin: 0;
}

.comparison-card p {
  font-size: 14px;
  color: #6b7280;
  margin-top: 8px;
}

.comparison-card .comparison-index {
  font-size: 13px;
  color: #303032;
  margin-top: 16px;
  line-height: 19px;
  font-weight: 500;
}

.comparison-card .comparison-content {
  font-size: 13px;
  color: #6e717c;
  margin-top: 14px;
  line-height: 19px;
}

.comparison-card .comparison-title {
  font-size: 14px;
  color: #303032;
  font-weight: 700;
  line-height: 19px;
}

@keyframes loadingSkeleton {
  0% {
    left: -100%;
  }

  /* Start position */
  100% {
    left: 100%;
  }

  /* End position */
}

@media screen and (max-width: 450px) {
  .h2-heading-wrapper4 {
    display: none;
  }

  .expand1 {
    gap: 32px 16px;
  }

  .frame-parent2 {
    /* gap: 12px 16px; */
    /* padding-right: 20px; */
    box-sizing: border-box;
  }

  .text-field5 {
    gap: 16px 32px;
    /* padding-bottom: 20px; */
    box-sizing: border-box;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.dot {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: loading 1s infinite;
}

.thinking-text {
    /* animation: l1 2s linear infinite alternate; */
    background: linear-gradient(to right, #aaa 0%, #bbbbbb 50%, #555555 30%, #666565 100%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: wipe 2.5s linear infinite;
}

@keyframes wipe {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -100% 0%;
    }
  }

.mb-220 {
  margin-bottom: 280px;
}

.mb-70 {
  margin-bottom: 70px;
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  color: #9150ff;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 3px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  font-family: sans-serif;
  font-size: 16px;
  color: #758199;
  animation: l1 1s linear infinite alternate;
}
@keyframes l1 {
  to {
    opacity: 0;
  }
}

  