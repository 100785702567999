.h2-heading46 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 19px;
  font-weight: 600;
  font-family: inherit;
}
.addy-assistant5,
.regular-addy-ai1 {
  position: relative;
  display: inline-block;
}
.addy-assistant5 {
  height: 12px;
  letter-spacing: -0.02em;
  line-height: 19px;
  font-weight: 600;
  font-size: 14px;
  min-width: 114px;
}
.regular-addy-ai1 {
  align-self: stretch;
  height: 10px;
  font-size: 13px;
  line-height: 150%;
  color: #485165;
  /* opacity: 80%; */
}
.expand-instance {
  width: calc(100% - 18px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: calc(100% - 18px);
}
.expand-icon5 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button6,
.f-r-a-m-e7 {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.model-select-card {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.button6 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0;
  row-gap: 20px;
  font-size: 16px;
  color: #485165;
  cursor: pointer;
  padding: 15px 10px !important;
}
.f-r-a-m-e7 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px !important;
  text-align: left;
  font-size: 26px;
  color: #0d0d25;
  font-family: Inter;
  max-width: 400px;
}

.dropdown-button:hover {
    transition: 0.3s all ease;
    /* border-color: #745dde !important;
    border-color: transparent !important; */
    background-color: #F0F1F6;
}
@media screen and (max-width: 450px) {
  .h2-heading46 {
    font-size: 21px;
    line-height: 15px;
  }
}
