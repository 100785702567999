.breadCrumbsNavigationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.currentBreadCrumb1Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.breadCrumbText {
    font-family: Inter;
    font-size: 13px;
    color: #474747;
    cursor: pointer;
}

.homeIcon {
    cursor: pointer;
}