.h2-heading {
    position: relative;
    line-height: 19px;
    white-space: nowrap;
}

.f-r-a-m-e {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.chat-page-parent {
    width: 100%;
    height: 100%;
}

#chat-history-parent {
    gap: 40px;
    justify-content: flex-start;
}

#addy-chat-history {
    display: block;
    height: 90%;
    max-width: 100vw;
    overflow-y: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.react-joyride__tooltip h1 {
    text-align: left !important;
    padding-left: 20px !important;
    font-family: Inter !important;
    font-size: 16px !important;
}

.react-joyride__tooltip div div {
    text-align: left !important;
    padding-left: 20px !important;
    font-family: Inter !important;
    font-size: 15px !important;
}


.addy-assistant {
    position: relative;
    line-height: 10px;
    font-weight: 500;
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;
}

.plus-icon {
    display: none;
    min-height: 18px;
}

.expand-icon,
.plus-icon {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.button {
    /* align-self: stretch; */
    /* flex: 1; */
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 12px;
    gap: 20px;
    cursor: pointer;
}

.no-border-and-space-between {
    border: none !important;
    justify-content: flex-start !important;
    gap: 10px !important;
}

.close-tooltip-icon {
    position: absolute;
    top: 10px;
    right: 0;
}

.close-icon,
.open-in-full {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 18px;
}

.addy-check-security {
    margin-right: 5px;
    cursor: pointer;
}

.open-in-full-parent {
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    cursor: default;
    width: 60px;
}
.open-in-full-parent img {
    cursor: pointer;
}

.button-parent,
.top-bar-new-message {
    background-color: #FFFFFF;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button-parent {
    /* width: 300px; */
    justify-content: flex-start;
    gap: 25px;
    cursor: pointer;
    flex: 1;
    justify-content: flex-end;
}

.top-bar-new-message {
    height: 50px;
    border-bottom: 1px solid #eff1f6;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 9px 15px;
    padding-right: 30px;
    top: 0;
    z-index: 99;
    position: absolute;
    /* gap: 20px; */
    text-align: left;
    font-size: 14px;
    color: #0d0d25;
    font-family: Inter;
    width: 100%;
}

.h2-heading1,
.h2-heading2 {
    position: relative;
    display: inline-block;
}

.small-h2-heading-new {
    font-size: 13px !important;
    width: 100px;
}

.small-description-text {
    font-size: 12px;
    width: 100px;
}

.info-card-small {
    width: 100px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid #eff1f6;
    border-radius: 10px;
    padding: 10px
}

.h2-heading1 {
    margin: 0;
    height: 17px;
    font-size: inherit;
    line-height: 160%;
    font-weight: 400;
    font-family: inherit;
    min-width: 24px;
}

.h2-heading2 {
    height: 12px;
    line-height: 19px;
}

.chat-with-your {
    margin-block-start: 0;
    margin-block-end: 9px;
}

.change-the-instructions {
    margin: 0;
}

.task-description {
    /* align-self: stretch; */
    font-size: fit-content;
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #485165;
    display: inline-block;
}

.f-r-a-m-e2,
.h2-heading-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.h2-heading-parent {
    flex: 1;
    flex-direction: column;
    min-width: 188px;
    font-size: 16px;
}

.f-r-a-m-e2 {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
}

.h2-heading3,
.h2-heading4 {
    position: relative;
    display: inline-block;
}

.h2-heading3 {
    margin: 0;
    height: 17px;
    font-size: inherit;
    line-height: 160%;
    font-weight: 400;
    font-family: inherit;
    min-width: 24px;
}

.h2-heading4 {
    height: 12px;
    line-height: 19px;
}

.add-necessary-bits {
    margin-block-start: 0;
    margin-block-end: 9px;
    line-height: 20px !important;
}

.drawer-button {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.you-can-change {
    margin: 0;
}

.add-necessary-bits-container {
    align-self: stretch;
    height: 61px;
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #485165;
    display: inline-block;
}

.f-r-a-m-e3,
.h2-heading-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.h2-heading-group {
    flex: 1;
    flex-direction: column;
    min-width: 188px;
    font-size: 16px;
}

.f-r-a-m-e3 {
    flex-direction: column;
    flex-wrap: wrap;
}

.h2-heading5,
.h2-heading6 {
    position: relative;
    display: inline-block;
}

.h2-heading5 {
    margin: 0;
    height: 17px;
    font-size: inherit;
    line-height: 160%;
    font-weight: 400;
    font-family: inherit;
    min-width: 24px;
}

.h2-heading6 {
    height: 12px;
    line-height: 19px;
}

.add-necessary-bits1 {
    margin-block-start: 0;
    margin-block-end: 9px;
}

.you-can-change1 {
    margin: 0;
}

.add-necessary-bits-container1 {
    align-self: stretch;
    height: 61px;
    position: relative;
    font-size: 14px;
    line-height: 150%;
    color: #485165;
    display: inline-block;
}

.h2-heading-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    min-width: 188px;
    font-size: 16px;
}

.f-r-a-m-e1,
.f-r-a-m-e4 {
    align-self: stretch;
    justify-content: flex-start;
}

.f-r-a-m-e4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 16px;
}

.f-r-a-m-e1 {
    padding: 0 50px 0 0;
    gap: 15px !important;
}

.addy-chat-history-select-buton {
    /* border-color: transparent; */
    /* background-color: #F0F1F6; */
    cursor: pointer;
    gap: 10px !important;
}

.chat-history-name-text {
    min-width: 80px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.text-field {
    align-self: stretch;
    /* border-bottom: 1px solid #eff1f6; */
    box-sizing: border-box;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    /* padding-bottom: 10px; */
    min-height: unset;
    height: 100%;
    max-width: 100%;
}

.chat-message {
    /* height: 95%; */
}

#addy-big-assistant-select {
    width: 100%;
}

.top {
    flex: 1;
    justify-content: flex-start;
    /* max-width: calc(100% - 9px); */
    text-align: left;
    font-size: 24px;
    color: #0d0d25;
    font-family: Inter;
    height: calc(100% - 140px);
    overflow-y: auto !important;
}

.f-r-a-m-e5 {
    align-self: stretch;
    width: 7px;
    position: relative;
    border-radius: 8px;
    background-color: #6e717c;
}

.chat,
.scroll-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.scroll-bar {
    height: 845px;
    width: 9px;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid #eff1f6;
    padding: 0 1px 783px 0;
}

.chat {
    /* width: 100%; */
    height: 100%;
    position: relative;
    background-color: #fff;
    /* box-shadow: 2px 3px 12px 1px rgba(72, 80, 109, 0.1),
    11px 1px 30px 20px rgba(72, 80, 109, 0.17); */
    /* border: 1px solid #eff1f6; */
    letter-spacing: normal;
    padding-top: 50px;
}

#addy-table-selection-div {
    padding-left: 14px;
    padding-right: 14px;
    width: 90%;
}

.top-layout {
    width: 100%;
}

.source-buttons {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
  
  .source-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #758199;
    cursor: pointer;
    border: 1px solid #ede7f8;
  }
  
  .source-button:hover {
    background-color: #ede7f8;
  }

@media screen and (max-width: 525px) {
    .f-r-a-m-e1 {
        gap: 20px 40px;
        /* padding-right: 96px; */
        box-sizing: border-box;
    }
}

@media screen and (max-width: 450px) {
    .f-r-a-m-e {
        /* display: none; */
        max-width: 50%;
        overflow-x: scroll;
        /* hide scrollbar */
        scrollbar-width: none;
        /* for Firefox */
        -ms-overflow-style: none;
        /* for Internet Explorer and Edge */
    }

    .f-r-a-m-e::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari and Opera */
    }

    .button-parent {
        gap: 32px 16px;
    }

    .h2-heading1,
    .h2-heading3,
    .h2-heading5 {
        font-size: 19px;
        line-height: 31px;
    }

    .f-r-a-m-e1 {
        /* padding-right: 20px; */
        box-sizing: border-box;
    }
}


.tooltip-content {
    position: relative;
    padding: 12px 6px;
  }
  
  .tooltip-title {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 16px;
    color: #0d0d25;
  }
  
  .tooltip-text {
    font-size: 14px;
    color: #4b5165;
    margin-bottom: 20px;
    line-height: 21px;
    max-height: 160px;
    overflow-y: auto;
    scrollbar-width: thin;
    /* scrollbar-color: transparent transparent; */
    /* overflow: hidden;
    text-overflow: ellipsis; */
  }
  
  .tooltip-footer a {
    font-size: 14px;
    color: #6c63ff;
    text-decoration: none;
  }
  
  .tooltip-footer a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .chat-page-parent .MuiPaper-elevation {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  
  .chat-page-parent .MuiPaper-elevation::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-page-parent .MuiPaper-elevation::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .chat-page-parent .MuiPaper-elevation::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
  }
  
  /* Define hover behavior */
  .chat-page-parent .MuiPaper-elevation:hover {
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  }
  
  .chat-page-parent .MuiPaper-elevation:hover::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
  }