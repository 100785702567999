.h2-heading28 {
  flex: 1;
  position: relative;
  line-height: 10px;
}

.heading1 {
  width: 158px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.addy-assistant4 {
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
  min-width: 102px;
}

.expand-icon4,
.plus-icon4 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plus-icon4 {
  display: none;
  min-height: 18px;
}

.expand-icon4 {
  object-fit: contain;
}

.item-row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
}

.button5 {
  align-self: stretch;
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px 12px;
  gap: 20px;
}

.close-icon4,
.open-in-full4 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 18px;
}

.default {
  height: 18px;
  justify-content: flex-start;
  gap: 16px;
}

.default,
.heading2,
.top-bar-new-message4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heading2 {
  height: 32px;
  width: 300px;
  justify-content: flex-start;
  gap: 32px;
}

.top-bar-new-message4 {
  align-self: stretch;
  border-bottom: 1px solid #eff1f6;
  justify-content: space-between;
  padding: 9px 16px;
  gap: 20px;
  flex-shrink: 0;
}

.plus-icon5 {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.h2-heading29 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 12px;
  font-weight: 600;
  display: inline-block;
  min-width: 79px;
}

.create-new {
  align-self: stretch;
  height: 10px;
  position: relative;
  line-height: 19px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 92px;
  text-decoration: none;
  color: #7150e0;
  margin: 0px;
}

.create-new-wrapper {
  width: 92px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #7150e0;
}

.chat-with-your-assistants1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.h2-heading30,
.h2-heading31 {
  position: relative;
  display: inline-block;
}

.h2-heading30 {
  flex: 1;
  line-height: 12px;
  font-weight: 400;
  min-width: 20px;
}

.h2-heading31 {
  height: 9px;
  width: 15px;
  line-height: 19px;
  flex-shrink: 0;
}

.h2-heading-wrapper1,
.heading5 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.h2-heading-wrapper1 {
  width: 0;
  border-radius: 4px;
  background-color: transparent;
  padding: 4px 0;
  text-align: left;
  font-size: 12px;
  color: transparent;
}

.heading5 {
  width: 46px;
  padding: 0 13px 8px;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

/* .heading5-small {
    padding-left: 2px;
    padding-right: 2px;
} */

.active {
  border-bottom: 2px solid #7150e0;
  color: #7150e0;
  width: fit-content;
}

.h2-heading32,
.h2-heading33 {
  height: 12px;
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 55px;
}

.h2-heading33 {
  min-width: 68px;
}

.heading3,
.heading4 {
  display: flex;
  align-items: flex-start;
}

.heading4 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 182px 0 0;
  gap: 20px;
  flex-wrap: nowrap !important;
}

.heading4-small {
  align-self: unset !important;
  gap: 5px !important;
}

.heading3 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3px 0 0;
  box-sizing: border-box;
  min-width: 270px;
  max-width: 100%;
}

.search-icon {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.heading-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 57px;
  max-width: 100%;
}

.h2-heading34 {
  margin: 0;
  height: 17px;
  position: relative;
  font-size: inherit;
  line-height: 160%;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-width: 24px;
}

.h2-heading-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.h2-heading35,
.regular-addy-ai {
  position: relative;
  display: inline-block;
}

.h2-heading35 {
  height: 12px;
  line-height: 19px;
  min-width: 120px;
}

.regular-addy-ai {
  align-self: stretch;
  height: 31px;
  font-size: 14px;
  line-height: 150%;
  color: #485165;
}

.h2-heading-parent15,
.item-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.h2-heading-parent15 {
  width: 262px;
  gap: 16px;
}

.item-inner {
  flex: 1;
  padding: 4px 0 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 16px;
}

.default1 {
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
  min-width: 49px;
}

.default-wrapper,
.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.default-wrapper {
  border-radius: 12px;
  background-color: #fff;
  padding: 6px 16px;
  font-size: 14px;
  color: #485165;
}

.item {
  width: 100%;
  border-radius: 8px;
  background-color: #f7effb;
  padding: 12px 12px 16px 16px;
  box-sizing: border-box;
  gap: 16px;
}

.addy-assistant-name {
  line-height: 110% !important;
}

.h2-heading36,
.h2-heading37 {
  position: relative;
  display: inline-block;
}

.h2-heading36 {
  margin: 0;
  font-size: inherit;
  line-height: 17px;
  font-weight: 400;
  font-family: inherit;
  min-width: 24px;
}

.h2-heading37 {
  font-size: 16px;
  line-height: 12px;
  min-width: 122px;
}

.h2-heading-parent16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 1px;
}

.infinite-shelf {
  width: 85px;
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
  min-width: 85px;
}

.infinite-shelf-wrapper {
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 15px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  color: #485165;
}

.frame-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.frame-group-small {
  flex-direction: column !important;
  gap: 10px !important;
}

.it-helps-you,
.pencil-icon {
  position: relative;
  flex-shrink: 0;
}

.item-description {
  width: 90% !important;
}

.it-helps-you {
  /* height: 31px; */
  /* width: 262px; */
  width: 99%;
  line-height: 150%;
  display: inline-block;
}

.pencil-icon {
  width: 18px;
  height: 18px;
  overflow: hidden;
}

.pencil-wrapper {
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 17px 0 0;
  box-sizing: border-box;
}

.it-helps-you-answer-clients-q-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: space-between; */
  /* gap: 20px; */
  /* max-width: 100%; */
  width: 100%;
  /*padding-left: 30px; */
  font-size: 14px;
  color: #485165;
}

.item1-wrapper {
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  gap: 5px;
  cursor: pointer;
}

.item1 {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px;
  box-sizing: border-box;
  gap: 5px;
  cursor: pointer;
}

.item1-wrapper:hover {
  background-color: #f6f7fb;
  transition: 0.3s all ease;
}

.item1-wrapper:hover .infinite-shelf-container {
  background-color: #fff !important;
  color: #485165 !important;
  transition: 0.3s all ease;
}

.assistants,
.frame-container,
.frame-parent,
.item-parent,
.item2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.item2 {
  width: 100%;
  border-radius: 8px;
  flex-direction: row;
  padding: 12px 12px 16px 16px;
  box-sizing: border-box;
  gap: 16px;
}

.h2-heading38 {
  margin: 0;
  height: 17px;
  position: relative;
  font-size: inherit;
  line-height: 160%;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-width: 24px;
}

.h2-heading-wrapper3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.h2-heading39,
.its-online-247 {
  height: 12px;
  position: relative;
  line-height: 19px;
  display: inline-block;
}

.its-online-247 {
  align-self: stretch;
  height: 31px;
  font-size: 14px;
  line-height: 150%;
  color: #485165;
}

.frame-wrapper1,
.h2-heading-parent17 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.h2-heading-parent17 {
  align-self: stretch;
  gap: 16px;
}

.frame-wrapper1 {
  flex: 1;
  padding: 4px 0 0;
  box-sizing: border-box;
  min-width: 170px;
}

.infinite-shelf1 {
  width: 85px;
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
  min-width: 85px;
}

.selected {
  background-color: #f7effb !important;
}

.selected .infinite-shelf-container {
  background-color: #fff !important;
  color: #485165 !important;
}

.infinite-shelf-container {
  border-radius: 12px;
  background-color: #eff1f6;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 15px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  color: #485165;
}

.infinite-shelf-container-small {
  min-width: 68% !important;
}

.frame-container {
  flex: 1;
  flex-direction: row;
  gap: 44px;
  max-width: 100%;
  font-size: 16px;
}

.loading-text {
  font-size: 14px;
  color: #758199;
  min-width: 39px;
  font-family: Inter;
}

.assistants,
.frame-parent,
.item-parent {
  flex-direction: column;
  max-width: 100%;
}

.item-parent {
  width: 100%;
  overflow-x: auto;
  gap: 10px;
  padding-right: 8px;
  font-size: 24px;
  color: #0d0d25;
  max-height: 300px;
}

.assistants,
.frame-parent {
  align-self: stretch;
}

.frame-parent {
  gap: 16px;
  color: #758199;
}

.addy-simple-vertical-scrollbar {
  overflow-y: scroll;
}

.addy-simple-vertical-scrollbar::-webkit-scrollbar {
  width: 0.3em;
}

.addy-simple-vertical-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.addy-simple-vertical-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.assistants {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 2px 3px 12px 1px rgba(72, 80, 109, 0.1),
    11px 1px 30px 20px rgba(72, 80, 109, 0.17);
  border: 1px solid #eff1f6;
  box-sizing: border-box;
  overflow: hidden;
  padding: 24px 15px 16px;
  gap: 29px;
  z-index: 1;
}

.h2-heading40,
.h2-heading41 {
  position: relative;
  display: inline-block;
}

.h2-heading40 {
  margin: 0;
  height: 17px;
  font-size: inherit;
  line-height: 160%;
  font-weight: 400;
  font-family: inherit;
  min-width: 24px;
}

.h2-heading41 {
  height: 12px;
  line-height: 19px;
}

.chat-with-your4 {
  margin-block-start: 0;
  margin-block-end: 9px;
}

.change-the-instructions4 {
  margin: 0;
}

.chat-with-your-container4 {
  align-self: stretch;
  height: 82px;
  position: relative;
  font-size: 14px;
  line-height: 150%;
  color: #485165;
  display: inline-block;
}

.h2-heading-parent18,
.h2-heading-parent19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.h2-heading-parent19 {
  flex: 1;
  flex-direction: column;
  min-width: 188px;
  font-size: 16px;
}

.h2-heading-parent18 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}

.h2-heading42,
.h2-heading43 {
  position: relative;
  display: inline-block;
}

.h2-heading42 {
  margin: 0;
  height: 17px;
  font-size: inherit;
  line-height: 160%;
  font-weight: 400;
  font-family: inherit;
  min-width: 24px;
}

.h2-heading43 {
  height: 12px;
  line-height: 19px;
}

.add-necessary-bits8 {
  margin-block-start: 0;
  margin-block-end: 9px;
}

.you-can-change8 {
  margin: 0;
}

.add-necessary-bits-container8 {
  align-self: stretch;
  height: 61px;
  position: relative;
  font-size: 14px;
  line-height: 150%;
  color: #485165;
  display: inline-block;
}

.h2-heading-parent20,
.h2-heading-parent21 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.h2-heading-parent21 {
  flex: 1;
  flex-direction: column;
  min-width: 188px;
  font-size: 16px;
}

.h2-heading-parent20 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}

.h2-heading44,
.h2-heading45 {
  position: relative;
  display: inline-block;
}

.h2-heading44 {
  margin: 0;
  height: 17px;
  font-size: inherit;
  line-height: 160%;
  font-weight: 400;
  font-family: inherit;
  min-width: 24px;
}

.h2-heading45 {
  height: 12px;
  line-height: 19px;
}

.add-necessary-bits9 {
  margin-block-start: 0;
  margin-block-end: 9px;
}

.button10 {
  align-self: stretch;
  height: 32px;
  width: "90%";
  margin-left: "auto";
  margin-right: "auto";
  text-transform: "none";
  color: "#f7f8fc";
  font-size: "16";
  border-radius: "8px";
  height: 32;
}

.createButtonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-assistant-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  gap: 10px;
}

.you-can-change9 {
  margin: 0;
}

.add-necessary-bits-container9 {
  align-self: stretch;
  height: 61px;
  position: relative;
  font-size: 14px;
  line-height: 150%;
  color: #485165;
  display: inline-block;
}

.button-help,
.h2-heading-parent22,
.h2-heading-parent23 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.h2-heading-parent23 {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 188px;
  font-size: 16px;
}

.button-help,
.h2-heading-parent22 {
  align-self: stretch;
}

.h2-heading-parent22 {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.button-help {
  flex-direction: column;
  padding: 0 192px 0 0;
  gap: 40px;
  margin-top: -170.5px;
  font-size: 24px;
  color: #0d0d25;
}

.assistants-container {
  margin-top: -664px;
  align-self: stretch;
  max-width: 100%;
}

.assistants-container,
.frame-section,
.text-field4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.text-field4 {
  height: fit-content;
  box-sizing: border-box;
  padding: 662px 20px 28px;
  max-width: 100%;
  font-size: 16px;
  color: #485165;
  width: 100%;
}

.frame-section {
  flex: 1;
  max-width: calc(100% - 9px);
  text-align: left;
  font-size: 14px;
  color: #0d0d25;
  font-family: Inter;
  max-width: 95%;
  width: inherit;
}

.rectangle-div {
  align-self: stretch;
  width: 7px;
  position: relative;
  border-radius: 8px;
  background-color: #6e717c;
}

.chat-assistant-dropdown5,
.scroll-bar4 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.scroll-bar4 {
  height: 845px;
  width: 9px;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid #eff1f6;
  padding: 0 1px 783px 0;
}

.chat-assistant-dropdown5 {
  width: 100%;
  position: relative;
  background-color: #fff;
  box-shadow: 2px 3px 12px 1px rgba(72, 80, 109, 0.1),
    11px 1px 30px 20px rgba(72, 80, 109, 0.17);
  border: 1px solid #eff1f6;
  letter-spacing: normal;
}

@media screen and (max-width: 525px) {
  .top-bar-new-message4 {
    flex-wrap: wrap;
  }

  .heading-parent {
    gap: 28px;
  }

  .button-help {
    gap: 20px 40px;
    padding-right: 96px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .heading2 {
    gap: 32px 16px;
  }

  .chat-with-your-assistants1,
  .heading4 {
    flex-wrap: wrap;
  }

  .heading4 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .h2-heading34,
  .h2-heading36,
  .h2-heading38,
  .h2-heading40,
  .h2-heading42,
  .h2-heading44 {
    font-size: 19px;
    line-height: 31px;
  }

  .button-help {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .text-field4 {
    height: auto;
  }
}
