.h2Heading {
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 120px;
  }
  .h2HeadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading1 {
    height: 10px;
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 39px;
  }
  .h2HeadingContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0 0;
  }
  .infoIcon {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frameGroup,
  .frameParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .frameGroup {
    justify-content: flex-start;
    gap: 8px;
    font-size: 14px;
    color: #758199;
  }
  .frameParent {
    align-self: stretch;
    justify-content: space-between;
    padding: 0 20px 0 0;
    gap: 20px;
  }
  .h2Heading2,
  .logan {
    display: inline-block;
  }
  .h2Heading2 {
    height: 10px;
    position: absolute;
    margin: 0 !important;
    top: 12.22px;
    left: -10px;
    line-height: 19px;
    min-width: 39px;
  }
  .logan {
    width: 42px;
    position: relative;
    line-height: 10px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .button,
  .plusIcon {
    overflow: hidden;
    flex-shrink: 0;
  }
  .plusIcon {
    height: 18px;
    width: 18px;
    position: relative;
    display: none;
  }
  .button {
    width: 216px;
    top: 34.22px;
    left: -10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 12px;
    gap: 8px;
    color: #0d0d25;
  }
  .button,
  .frameChild,
  .h2Heading3 {
    position: absolute;
    margin: 0 !important;
  }
  .frameChild {
    height: 6px;
    width: 6px;
    top: 47.22px;
    right: 79px;
    border-radius: 50%;
    background-color: #32d788;
  }
  .h2Heading3 {
    height: 10px;
    top: 85.44px;
    left: -10px;
    line-height: 19px;
    display: inline-block;
    min-width: 59px;
  }
  .mentz,
  .plusIcon1 {
    position: relative;
    overflow: hidden;
  }
  .mentz {
    width: 43px;
    line-height: 10px;
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .plusIcon1 {
    height: 18px;
    width: 18px;
    flex-shrink: 0;
    display: none;
  }
  .button1,
  .h2Heading4 {
    position: absolute;
    margin: 0 !important;
    left: -10px;
  }
  .button1 {
    width: 216px;
    top: 107.44px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 12px;
    gap: 8px;
    color: #0d0d25;
  }
  .h2Heading4 {
    height: 10px;
    top: 158.66px;
    line-height: 19px;
    display: inline-block;
    min-width: 32px;
  }
  .loganmentzcbrecom {
    width: 160px;
    position: relative;
    line-height: 10px;
    font-weight: 500;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .button2,
  .plusIcon2 {
    overflow: hidden;
    flex-shrink: 0;
  }
  .plusIcon2 {
    height: 18px;
    width: 18px;
    position: relative;
    display: none;
  }
  .button2 {
    width: 216px;
    margin: 0 !important;
    position: absolute;
    bottom: 139.34px;
    left: -10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 12px;
    gap: 8px;
    color: #0d0d25;
  }
  .div,
  .h2Heading5 {
    display: inline-block;
  }
  .h2Heading5 {
    height: 10px;
    position: absolute;
    margin: 0 !important;
    bottom: 110.12px;
    left: -10px;
    line-height: 19px;
    min-width: 42px;
  }
  .div {
    width: 112px;
    position: relative;
    line-height: 10px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .button3,
  .plusIcon3 {
    overflow: hidden;
    flex-shrink: 0;
  }
  .plusIcon3 {
    height: 18px;
    width: 18px;
    position: relative;
    display: none;
  }
  .button3 {
    width: 216px;
    margin: 0 !important;
    position: absolute;
    bottom: 66.12px;
    left: -10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 12px;
    gap: 8px;
    white-space: nowrap;
    color: #0d0d25;
  }
  .h2Heading6 {
    height: 10px;
    bottom: 36.9px;
    left: -10px;
    line-height: 19px;
    display: inline-block;
    min-width: 80px;
  }
  .button4,
  .frameItem,
  .h2Heading6 {
    position: absolute;
    margin: 0 !important;
  }
  .button4 {
    border: 0;
    background-color: transparent;
    height: 32px;
    width: 216px;
    bottom: -7.1px;
    left: -10px;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    color: #0d0d25;
  }
  .frameItem {
    height: 6px;
    width: 6px;
    right: 79px;
    bottom: 5.9px;
    border-radius: 50%;
    background-color: #32d788;
  }
  .h2Heading7 {
    height: 12px;
    position: relative;
    line-height: 19px;
    display: inline-block;
  }
  .h2HeadingFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0 0;
  }
  .closeIcon {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
    min-height: 18px;
    cursor: pointer;
  }
  .frameDiv {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .addyGivesYou,
  .blankLine {
    margin: 0;
  }
  .span {
    color: #485165;
    white-space: pre-wrap;
  }
  .spotOn {
    color: #0d0d25;
  }
  .hasAScore {
    white-space: pre-wrap;
  }
  .blankLine1,
  .itsLikelyDoesnt,
  .spotOnHasAScoreOf910 {
    margin: 0;
  }
  .tooltipExplainerHeader {
    display: flex;
    gap: 7px;
    flex-direction: row;
    align-items: center;
  }
  .span1 {
    white-space: pre-wrap;
  }
  .acceptable {
    color: #0d0d25;
  }
  .hasAScore1 {
    white-space: pre-wrap;
  }
  .acceptableHasAScoreOf78,
  .blankLine2,
  .youMayNeed {
    margin: 0;
  }
  .span2 {
    white-space: pre-wrap;
  }
  .innacurate {
    color: #0d0d25;
  }
  .isBelow6 {
    color: #485165;
  }
  .addyDidntRecognize,
  .innacurateIsBelow6 {
    margin: 0;
  }
  .h2Heading8 {
    align-self: stretch;
    height: 262px;
    position: relative;
    line-height: 150%;
    display: inline-block;
  }
  .frameInner {
    top: 65px;
    background-color: #32d788;
  }
  .ellipseDiv,
  .frameChild1,
  .frameInner {
    width: 6px;
    height: 6px;
    margin: 0 !important;
    left: 0;
    border-radius: 50%;
    z-index: 1;
  }
  .ellipseDiv {
    top: calc(50% - 3px);
    background-color: #edbc0e;
  }
  .frameChild1 {
    bottom: 44px;
    background-color: #ed360e;
  }
  .h2HeadingGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    font-size: 14px;
    color: #485165;
  }
  .polygonIcon {
    width: 27px;
    height: 27px;
    position: absolute;
    margin: 0 !important;
    top: -13px;
    right: 16px;
    border-radius: 4px;
  }
  .frameContainer,
  .h2HeadingParent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }
  .frameContainer {
    flex: 1;
    box-shadow: 2px 3px 12px 1px rgba(72, 80, 109, 0.1),
      11px 1px 30px 20px rgba(72, 80, 109, 0.17);
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    padding: 20px 20px 28px;
    gap: 24px;
    z-index: 1;
    font-size: 16px;
    color: #0d0d25;
    position: absolute;
    top: 120px;
    left: 20px;
    width: 220px;
  }
  .h2HeadingParent {
    align-self: stretch;
    flex-direction: row;
  }
  .mMRomeoMichiganUnitedStat,
  .mMRomeoMichiganUnitedStatInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mMRomeoMichiganUnitedStatInner {
    align-self: stretch;
    padding: 0 0 0 10px;
    font-size: 14px;
    color: #758199;
  }
  .mMRomeoMichiganUnitedStat {
    flex: 1;
    gap: 7px;
    max-width: 100%;
  }
  .mMRomeoMichiganUnitedStatWrapper {
    width: 351px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 15px;
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    font-size: 16px;
    color: #0d0d25;
    font-family: Inter;
  }
  