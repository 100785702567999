.commercial1,
.commercialDesign,
.topBarNewMessage1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.closeWrapper,
.topBarNewMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px 0 0;
}

.wrapperUnion {
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topBarNewMessage {
  align-self: stretch;
  border-bottom: 1px solid #eff1f6;
  flex-direction: row;
  padding: 9px 9px 7px;
  gap: 26.5px;
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: left;
  font-size: 12px;
  color: #ab80f1;
  font-family: Inter;
  background-color: rgba(255, 255, 255, 1);
}

.commercialDesign {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.commercial1 {
  flex-direction: column;
  padding: 8px 0 0;
  box-sizing: border-box;
}

.commercial,
.scrollBar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.commercial {
  width: 300px;
  min-width: 300px;
  /* max-width: 100%; */
  line-height: normal;
  letter-spacing: normal;
}

.expandableInstanceWrapper,
.topBarNewMessageParent {
  box-sizing: border-box;
}

.expandableInstanceWrapper {
  align-self: stretch;
  flex-direction: row;
}

.topBarNewMessageParent {
  flex: 1;
  background-color: #fff;
  border-top: 1px solid #eff1f6;
  border-right: 1px solid #eff1f6;
  border-bottom: 1px solid #eff1f6;
  flex-direction: column;
  padding: 0 0 38px;
  gap: 32px;
}

.unionIcon {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 1px;
  transform: scale(2.25);
}

.expandableInstance,
.expandableInstanceWrapper,
.topBarNewMessageParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}



.h2Heading {
  /* height: 9px; */
  position: relative;
  font-size: 24px;
  text-transform: uppercase;
  display: inline-block;
  min-width: 35px;
}




.topBarNewMessage1 {
  flex-direction: column;
  padding: 3.5px 0 0;
}

.checkMark {
  color: #ab83eb;
  background: transparent;
}

.loadingText {
  font-size: 24px;
  color: #758199;
  min-width: 39px;
  margin-left: 20px;
  font-family: Inter;
  gap: 12px;
  /* align-items: center; */
}

.loadingTexCenter {
  font-size: 24px;
  color: #758199;
  min-width: 39px;
  margin-left: 20px;
  font-family: Inter;
  gap: 12px;
}