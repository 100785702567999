.h2Heading {
    height: 10px;
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 39px;
  }
  .h2HeadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0 0;
  }
  .infoIcon {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frameGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameChild {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
  }
  .ellipseWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading1 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameItem {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading2 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameInner {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading3 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .ellipseDiv {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseWrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading4 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameChild1 {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseWrapper2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading5 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameChild2 {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseWrapper3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading6 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameChild3 {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseWrapper4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading7 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent6 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameChild4 {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseWrapper5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading8 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameParent7 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameChild5 {
    width: 6px;
    height: 6px;
    position: relative;
    border-radius: 50%;
    background-color: #32d788;
  }
  .ellipseWrapper6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .h2Heading9 {
    position: relative;
    line-height: 10px;
    display: inline-block;
    min-width: 54px;
  }
  .frameContainer,
  .frameParent,
  .frameParent8 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .frameContainer,
  .frameParent {
    flex-direction: column;
    gap: 63.2px;
  }
  .frameParent {
    gap: 55.2px;
    text-align: left;
    font-size: 14px;
    color: #758199;
    font-family: Inter;
  }
  