.addyAi {
  text-decoration: none;
  align-self: stretch;
  height: 10px;
  position: relative;
  line-height: 19px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.addyContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}
.plusIcon {
  display: none;
  min-height: 18px;
}
.expandIcon,
.plusIcon {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eff1f6;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 11px;
  gap: 4px;
}
.openInFull {
  display: none;
}
.closeIcon,
.openInFull {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.closeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px 0 0;
}
.h2Heading {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 150%;
  font-weight: 500;
  font-family: inherit;
}
.inputIcon {
  height: 58px;
  width: 58px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}
.email {
  align-self: stretch;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 6px 0 0;
}
.h2Heading1 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 700;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
.h2Heading2 {
  position: relative;
  font-size: 13px;
  letter-spacing: -0.01em;
  line-height: 20px;
  font-weight: 500;
  color: rgba(40, 48, 63, 0.6);
  display: inline-block;
  max-width: 180px;
}
.errorInfoText {
  font-size: 13px;
  letter-spacing: -0.01em;
  line-height: 20px;
  font-weight: 500;
  color: rgba(40, 48, 63, 0.6);
}
.infoText {
    font-size: 12px;
    letter-spacing: -0.01em;
    line-height: 20px;
    font-weight: 500;
    max-width: 250px;
    color: rgba(40, 48, 63, 0.6);
}
.emailHeading,
.emailHeadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.emailHeading {
  align-self: stretch;
  justify-content: flex-start;
  gap: 2px;
}
.emailHeadingWrapper {
  flex: 1;
  justify-content: flex-end;
}
.emailInput,
.inputContainer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.inputContainer {
  align-items: center;
  height: fit-content;
  gap: 14px;
}
.emailInput {
  align-items: flex-start;
  height: fit-content;
  padding: 0 0 0 3px;
  font-size: 15px;
}
.h2Heading3 {
  width: 93%;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #6e717c;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.header,
.headerTitle {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerTitle {
  flex-direction: column;
  gap: 20px;
}
.header {
  flex-direction: row;
  padding-bottom: 25px;
}
.h2Heading4 {
  position: relative;
  line-height: 9px;
  font-weight: 500;
  display: inline-block;
  min-width: 78px;
}
.historyDetailsChild {
  height: 17px;
  width: 0;
  border-radius: 4px;
  background-color: transparent;
  display: none;
}
.historyDetails {
  height: 17.5px;
  border-bottom: 1px solid #7150e0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 17px 8px;
}
.h2Heading5 {
  position: relative;
  line-height: 9px;
  color: #758199;
  text-align: left;
  display: inline-block;
  min-width: 35px;
}
.historyItem,
.historyItems {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.historyItem {
  height: 17px;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}
.historyItems {
  width: 185px;
  justify-content: flex-start;
  padding: 0 3px;
  box-sizing: border-box;
}
.h2Heading6 {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #0d0d25;
}
.amazonWebServices,
.may2021 {
  position: relative;
  line-height: 150%;
  font-size: 13px;
}
.may2021 {
  display: inline-block;
}
.link {
    text-decoration: none;
    /* display: inline-block; */
    color: #745dde;
    font-size: 13px;
}
.entryPeriod {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.current {
  position: relative;
  line-height: 10px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
  font-size: 12px;
  color: #70C278;
}
.currentStatus {
  border-radius: 12px;
  background-color: #F1FCF0;
  border: 1px solid #70C278;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  text-align: center;
}
.entryContent,
.entryDetails {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.entryContent {
  flex-direction: column;
  padding: 0 21px 0 0;
  gap: 5px;
}
.entryDetails {
  width: 256px;
  flex-direction: row;
  box-sizing: border-box;
  flex-shrink: 0;
}
.item {
  align-self: stretch;
  border-bottom: 1px solid rgba(117, 129, 153, 0.2);
  flex-direction: row;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #485165;
}
.history,
.historyList,
.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.historyList {
  flex: 1;
  flex-direction: column;
  gap: 10px;
}
.history {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 1px;
  text-align: center;
  font-size: 13px;
  color: #7150e0;
}
.h2Heading7 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 110px;
}
.may2019,
.nationalScienceFoundation {
  position: relative;
  line-height: 150%;
}
.may2019 {
  align-self: stretch;
  height: 10px;
  display: inline-block;
}
.entryPeriod1 {
  width: 189px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  font-size: 14px;
  color: #485165;
}
.entryContent1,
.entryDetails1,
.item1 {
  box-sizing: border-box;
  justify-content: flex-start;
}
.entryContent1 {
  width: 169px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 9px;
}
.entryDetails1,
.item1 {
  flex-direction: row;
}
.entryDetails1 {
  width: 256px;
  display: flex;
  align-items: flex-start;
  padding: 0 0 54px;
  flex-shrink: 0;
}
.item1 {
  height: 101.5px;
  border-bottom: 1px solid rgba(117, 129, 153, 0.2);
  padding: 16px 0 0 16px;
  font-size: 16px;
  color: #0d0d25;
}
.content,
.headerContainer,
.item1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.headerContainer {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.content {
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: 20px;
  color: #28303f;
}
.lightningIcon {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.h2Heading8 {
  height: 10px;
  flex: 1;
  position: relative;
  line-height: 19px;
  font-weight: 500;
  display: inline-block;
}
.buttonIcon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.addContent,
.addElements {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addElements {
  width: 240px;
  justify-content: flex-start;
  gap: 16px;
}
.addContent {
  flex: 1;
  box-shadow: 0 1px 10px rgba(226, 229, 236, 0.4);
  border-radius: 14px;
  background-color: #fff;
  border: 1px solid #a179e4;
  overflow: hidden;
  justify-content: space-between;
  padding: 8px 7px 8px 15px;
  gap: 8px;
}
.addContentWrapper,
.historyFooter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.addContentWrapper {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
}
.historyFooter {
  width: 266px;
  height: 130px;
  flex-direction: row;
  padding: 0 1px 82px;
  box-sizing: border-box;
  flex-shrink: 0;
  color: #6e717c;
}
.h2Heading10,
.h2Heading9 {
  position: relative;
  display: none;
}
.h2Heading9 {
  width: 50px;
  height: 0;
  font-size: 11px;
  letter-spacing: -0.01em;
  line-height: 150%;
  font-weight: 500;
  color: rgba(40, 48, 63, 0.6);
}
.h2Heading10 {
  width: 80px;
  font-size: 16px;
  line-height: 19px;
  color: #758199;
}
.plusIcon1 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.generate {
  align-self: stretch;
  width: 121px;
  position: relative;
  line-height: 19px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plusIcon2 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1,
.messageTopBar,
.root {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.button1 {
  margin-right: -50px;
  height: 32px;
  box-shadow: 0 1px 10px rgba(112, 75, 175, 0.2);
  border-radius: 8px;
  background: linear-gradient(92deg, #a388f3, #ab7ff1 45.5%, #ab83eb);
  border: 0.5px solid #cab2f2;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  padding: 6.5px 78px;
  gap: 8px;
  text-align: center;
  font-size: 16px;
  color: #f7f8fc;
}
.messageTopBar,
.root {
  background-color: #fff;
}
.messageTopBar {
  width: 297px;
  /* border-top: 1px solid #eff1f6;
  border-right: 1px solid #eff1f6;
  border-bottom: 1px solid #eff1f6; */
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 23px;
  text-align: left;
  font-size: 14px;
  color: #0d0d25;
  font-family: Inter;
}
.root {
  flex-direction: row;
  align-items: flex-start;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 15px;
}
