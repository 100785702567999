.suggestAReply,
.toThisEmail {
  position: relative;
  display: inline-block;
}
.suggestAReply {
  letter-spacing: -0.02em;
  line-height: 19px;
  font-weight: 600;
  min-width: 116px;
}
.selectDropdown {
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  background-color: #f5f5f5;
}
.toThisEmail {
  display: flex;
  height: 10px;
  font-size: 14px;
  line-height: 150%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.suggestionDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.expandIcon {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  width: 100%;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  /* border: 1.5px solid #eff1f6; */
  border: 1.5px solid #e2e5ee;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 15px;
  gap: 0;
  row-gap: 20px;
  text-align: left;
  font-size: 15px;
  color: #485165;
  font-family: Inter;
  cursor: pointer;
}
.button:hover {
  /* background-color: #745DDE; */
  background-color: #f1f1f1;
  /* border-color: #745DDE; */
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); */
  /* transform: scale(1.005); */
  transition: 0.3s all ease;
}
