.h2Heading {
    position: relative;
    letter-spacing: -0.04em;
    line-height: 19px;
    font-weight: 600;
}

.h2Heading1,
.johnWantsTo {
    margin: 0;
    position: relative;
}

.h2Heading1 {
    font-size: inherit;
    letter-spacing: -0.04em;
    line-height: 19px;
    font-weight: 600;
    font-family: inherit;
    color: #0d0d25;
}

.johnWantsTo {
    align-self: stretch;
    line-height: 20px;
    font-size: 13px;
}

.userMessage {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.div,
.waysYouCan {
    position: relative;
    font-weight: 500;
}

.waysYouCan {
    font-size: 13px;

    letter-spacing: -0.01em;
    line-height: 30px;
    color: #101828;
}

.div {
    height: 10px;
    line-height: 19px;
    display: inline-block;
    min-width: 18px;
}

.politelyAgree {
    line-height: 19px;
    font-weight: 600;
}

.heyMikeThanks {
    line-height: 20px;
    color: #475467;
    font-size: 13px;
}

.politelyAgreeContainer {
    flex: 1;
    position: relative;
    display: inline-block;
    min-width: 163px;
}

.button {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 4px;
    cursor: pointer;
    min-height: fit-content;
}

.button:hover {
    background-color: #f7f8fa;
    transition: 0.3s ease;
}

.div1 {
    height: 10px;
    position: relative;
    line-height: 19px;
    font-weight: 500;
    display: inline-block;
    min-width: 18px;
}

.politelyDecline {
    line-height: 19px;
    font-weight: 600;
}

.heyMikeSorry {
    line-height: 20px;
    color: #475467;
}

.politelyDeclineContainer {
    flex: 1;
    position: relative;
    display: inline-block;
    min-width: 163px;
}

.button1 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eff1f6;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 11px 0;
    gap: 4px;
}

.h2Heading2 {
    height: 10px;
    flex: 1;
    position: relative;
    line-height: 19px;
    font-weight: 500;
    display: inline-block;
}

.buttonIcon {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
}

.generateButton,
.generateContainer {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.generateContainer {
    width: 269px;
    justify-content: flex-start;
    gap: 16px;
}

.generateButton {
    height: 48px;
    box-shadow: 0 1px 10px rgba(226, 229, 236, 0.4);
    border-radius: 14px;
    background-color: #fff;
    border: 1px solid #a179e4;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 8px 7px 8px 19px;
    gap: 8px;
    color: #6e717c;
}

.messageContent,
.messageField,
.replyButtons {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.replyButtons {
    gap: 15px;
    color: #0d0d25;
    /* max-height: 400px; */
    /* overflow-y: scroll; */
}

.messageContent,
.messageField {
    gap: 24px;
}

.messageContent {
    position: relative;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    padding: 22px 19px;
    font-size: 14px;
    color: #475467;
}

.closeIcon {
    position: absolute;
    top: 7px;
    right: 6px;
    cursor: pointer;
    padding: 4px;
}

.closeIcon:hover {
    background-color: #f7f8fa;
    border-radius: 50%;
    transition: 0.3s ease;
}

.messageField {
    text-align: left;
    font-size: 26px;
    color: #7150e0;
    font-family: Inter;
}