@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
  overflow: hidden;
  height: 100dvh;
}

.analyze-attachment-indicator-parent {
  position: absolute;
  z-index: 9999;
  top: 50px;
}

.analyze-attachment-parent {
  width: unset !important;
}

* {
  box-sizing: border-box;
}
