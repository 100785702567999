.swiper-button-next::after {
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-size: 13px;
    color: #485165;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
}
.swiper-button-next {
    right: 5px !important;
}
.swiper-button-prev::after {
    /* width: 30px;
    height: 30px; */
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-size: 13px;
    color: #485165;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 5px;

}
.swiper-button-prev {
    left: 5px !important;
}

.swiper {
    padding-left: 50px;
    padding-right: 50px;
    /* width: 90% !important; */
}
.swiper-wrapper {
    /* padding-left: 30px; */
}

.custom-swiper {
    display: flex;
    justify-content: space-between;
}
  
.custom-swiper .swiper-slide {
    float: none;
    max-width: fit-content;
    width: fit-content;
}